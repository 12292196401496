<template>
  <div class="row">
    <div class="col-lg-4 col-md-5">
      <person-card :id="id"> </person-card>
      <!--<members-card> </members-card>-->
    </div>
    <div class="col-lg-8 col-md-7">
      <edit-person-form :id="id"> </edit-person-form>
    </div>
    <div class="col-md-12">
      <PersonOrdersList :id="id"></PersonOrdersList>
    </div>
  </div>
</template>
<script>
import EditPersonForm from "./EditPersonForm.vue";
import PersonCard from "./PersonCard.vue";
import PersonOrdersList from "./PersonOrdersList.vue";
//import MembersCard from "./MembersCard.vue";
import { useStore } from "vuex";
import { watch, onMounted, onBeforeMount } from "vue";

export default {
  components: {
    EditPersonForm,
    PersonCard,
    PersonOrdersList,
  },
  props: {
    id: {
      type: Number,
    },
  },
  setup(props) {
    const store = useStore();

    onBeforeMount(() => {
      store.dispatch("persons/clearPerson");
    });

    onMounted(() => {
      store.dispatch("persons/fetchPerson", props.id);
    });

    watch(
      () => props.id,
      () => {
        store.dispatch("persons/fetchPerson", props.id);
      }
    );
  },
};
</script>
<style></style>
