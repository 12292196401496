<template>
  <div class="row">
    <div class="col-lg-12 col-md-7">
      <edit-person-form ref="personForm" :mode="'create'"> </edit-person-form>
    </div>
    <div class="col-lg-12 text-center">
      <p-button @click="onSubmit"> Submit </p-button>
    </div>
  </div>
</template>
<script>
import EditPersonForm from "./EditPersonForm.vue";
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: {
    EditPersonForm,
  },
  props: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    const personForm = ref(null);

    const onSubmit = async () => {
      const { result: personResult, values: personValues } =
        await personForm.value.validate();

      if (personResult.valid === true) {
        store
          .dispatch("persons/createPerson", personValues)
          .then(({ result, message, id }) => {
            if (result === true) {
              // reset the form and the field values to their initial values
              //resetForm();
              router.push(`/persons/details/${id}`);
            } else {
              console.log(message);
              //errorMessage.value = message;
            }
          });
      } else {
        //alert("noes");
      }
      console.log(personValues);
    };

    return {
      personForm,
      onSubmit,
    };
  },
};
</script>
<style></style>
