<template>
  <div class="row">
    <div class="col-lg-12 col-md-7">
      <edit-order-form ref="orderForm" :mode="'create'"> </edit-order-form>
    </div>
    <div class="col-lg-12 text-center">
      <p-button :disabled="isSubmitting" @click="onSubmit"> Submit </p-button>
    </div>
  </div>
</template>
<script>
import EditOrderForm from "./EditOrderForm.vue";
import { ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: {
    EditOrderForm,
  },
  props: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    onBeforeMount(() => store.dispatch("contacts/clearContact"));

    const orderForm = ref(null);

    const isSubmitting = ref(false);
    const onSubmit = async () => {
      isSubmitting.value = true;
      const { result: orderResult, values: orderValues } =
        await orderForm.value.validate();

      if (orderResult.valid === true) {
        store
          .dispatch("orders/createOrder", orderValues)
          .then(({ result, message, id }) => {
            if (result === true) {
              // reset the form and the field values to their initial values
              //resetForm();
              router.push(`/orders/details/${id}`);
            } else {
              console.log(message);
              //errorMessage.value = message;
            }
          });
      } else {
        isSubmitting.value = false;
      }
    };

    return {
      orderForm,
      onSubmit,
      isSubmitting,
    };
  },
};
</script>
<style></style>
