<template>
  <div class="row">
    <div class="col-md-10">
      <h4 class="title">Persons</h4>
    </div>
    <div class="col-md-2 mt-3">
      <p-button :round="true" class="pull-right" @click="handleAddPerson"
        >Add person</p-button
      >
    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Persons</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select
            v-model="pagination.perPage"
            class="select-default"
            placeholder="Per page"
          >
            <el-option
              v-for="item in pagination.perPageOptions"
              :key="item"
              class="select-default"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <el-input
              v-model="searchQuery"
              class="input-sm"
              placeholder="Search"
            >
              <template #suffix>
                <el-icon class="el-input__icon nc-icon nc-zoom-split" />
              </template>
            </el-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            class="table-striped"
            :data="queriedData()"
            border
            style="width: 100%"
            :default-sort="{ prop: sorting.column, order: sorting.order }"
            @sort-change="sortTable($event)"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              :sortable="column.sortable"
            >
            </el-table-column>
            <el-table-column
              :min-width="120"
              fixed="right"
              class-name="td-actions"
              label="Actions"
            >
              <template #default="props">
                <p-button
                  type="info"
                  size="sm"
                  icon
                  @click="handleShow(props.$index, props.row)"
                >
                  <i class="fa fa-user"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">
            Showing {{ from + 1 }} to {{ to }} of {{ total() }} entries
          </p>
        </div>
        <div class="col-sm-6">
          <p-pagination
            v-model="pagination.currentPage"
            class="pull-right"
            :per-page="pagination.perPage"
            :total="pagination.total"
            @input="pagination.currentPage = $event"
          >
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PPagination from "src/components/UIComponents/Pagination.vue";
import { useStore } from "vuex";
import { computed, ref, onMounted, watch, reactive } from "vue";
import { useRouter } from "vue-router";
import _ from "lodash";

export default {
  components: {
    PPagination,
  },
  props: {
    filter: {
      type: Array,
      description: "Array of filters",
      default: () => [],
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    /* Pagination */
    const pagination = reactive({
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
      total: 0,
    });

    /* Table sorting */
    const sorting = reactive({
      column: "id",
      order: "asc",
    });
    const sortTable = ({ prop, order }) => {
      if (order != null) {
        sorting.column = prop;
        sorting.order = [
          { key: "ascending", value: "asc" },
          { key: "descending", value: "desc" },
        ].find((el) => el.key === order).value;
      } else {
        sorting.column = "id";
        sorting.order = "asc";
      }
    };

    const searchQuery = ref("");

    watch(
      [
        sorting,
        () => pagination.perPage,
        () => pagination.currentPage,
        searchQuery,
      ],
      _.debounce(async () => {
        pagination.total = (
          await store.dispatch("persons/fetchPersons", {
            page: pagination.currentPage,
            perPage: pagination.perPage,
            searchTerm: searchQuery.value,
            searchFields: ["firstName", "lastName"],
          })
        )?.total;
      }, 500)
    );

    onMounted(async () => {
      pagination.total = (
        await store.dispatch("persons/fetchPersons", {
          page: pagination.currentPage,
          perPage: pagination.perPage,
          orderBy: sorting.column,
          sortOrder: sorting.order,
        })
      )?.total;
    });

    const handleAddPerson = () => {
      router.push("create");
    };

    return {
      handleAddPerson,
      pagination,
      sortTable,
      searchQuery,
      sorting,
      propsToSearch: ["firstName", "lastName", "companyRole"],
      tableColumns: [
        {
          prop: "firstName",
          label: "First Name",
          minWidth: 80,
          sortable: "custom",
        },
        {
          prop: "lastName",
          label: "Last name",
          minWidth: 80,
          sortable: "custom",
        },
        {
          prop: "companyRole",
          label: "Company role",
          minWidth: 80,
          sortable: "custom",
        },
      ],
      tableData: computed(() => store.state.persons.persons),
      showModal: ref(false),
      modalDataToShow: ref(null),
    };
  },
  computed: {
    pagedData() {
      return this.tableData /*.slice(this.from, this.to)*/;
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  methods: {
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        //  this.pagination.total = this.tableData.length;
        return this.pagedData;
      }
      let result = this.tableData;
      //this.pagination.total = result.length;
      return result /*.slice(this.from, this.to)*/;
    },
    total() {
      //this.pagination.total = this.tableData.length;
      return this.pagination.total;
    },
    handleShow(index, row) {
      this.$router.push(`details/${row.id}`);
    },
  },
};
</script>

<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}
</style>
