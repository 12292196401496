<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h1 class="title">{{ orderName }}</h1>
      </div>
      <div class="col-md-12">
        <h4 class="title">Overview</h4>
      </div>
    </div>
    <!--Stats cards-->
    <div class="row">
      <div
        v-for="stats in statsCards"
        :key="stats.value"
        class="col-lg-2 col-md-6 col-sm-6"
      >
        <stats-card
          :type="stats.type"
          :icon="stats.icon"
          :small-title="stats.title"
          :title="stats.value.value"
        >
          <template #footer>
            <div class="stats">
              <i :class="stats.footerIcon"></i>
              {{ stats.footerText }}
            </div>
          </template>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ActivitiesList :filter="orderFilter" @new-activity="loadStats">
        </ActivitiesList>
      </div>
    </div>
  </div>
</template>

<script>
import ActivitiesList from "src/components/Sections/Activities/ActivitiesList.vue";
import StatsCard from "src/components/UIComponents/Cards/StatsCard";
import { useStore } from "vuex";
import { computed, onMounted, ref, inject } from "vue";

const createConfig = (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type": "application/json",
    },
  };
  return config;
};

export default {
  components: {
    ActivitiesList,
    StatsCard,
  },
  props: {
    id: {
      type: Number,
      default: -1,
    },
  },
  setup(props) {
    const store = useStore();
    const axios = inject("axios"); // inject axios

    const loadStats = async () => {
      stats.value = await axios
        .get("report", {
          ...createConfig(store.getters["getToken"]),
          params: {
            type: "hours",
            grouping: ["orderId", "type"],
            orderId: props.id,
          },
        })
        .then((res) => {
          return res.data.data;
        })
        .catch(() => {
          //this.error = error;
          //console.log(error);

          return 0;
        });
    };

    const orderName = ref("");

    onMounted(async () => {
      store
        .dispatch("orders/fetchOrder", props.id)
        .then(() => (orderName.value = store.state.orders.order.name));

      loadStats();
    });

    const stats = ref([]);
    const devHours = computed(
      () => stats.value.find((el) => el.type == "development")?.duration ?? 0
    );
    const callHours = computed(
      () => stats.value.find((el) => el.type == "call")?.duration ?? 0
    );
    const meetingHours = computed(
      () => stats.value.find((el) => el.type == "meeting")?.duration ?? 0
    );

    const helpdeskHours = computed(
      () => stats.value.find((el) => el.type == "helpdesk")?.duration ?? 0
    );

    const totalHours = computed(
      () =>
        devHours.value +
        callHours.value +
        meetingHours.value +
        helpdeskHours.value
    );

    const statsCards = [
      {
        type: "warning",
        icon: "nc-icon nc-globe",
        title: "Dev.",
        value: devHours,
        footerText: "Updated now",
        footerIcon: "nc-icon nc-refresh-69",
      },
      {
        type: "success",
        icon: "nc-icon nc-money-coins",
        title: "Call",
        value: callHours,
        footerText: "Last day",
        footerIcon: "nc-icon nc-calendar-60",
      },
      {
        type: "danger",
        icon: "nc-icon nc-vector",
        title: "Meeting",
        value: meetingHours,
        footerText: "In the last hour",
        footerIcon: "nc-icon nc-bell-55",
      },
      {
        type: "danger",
        icon: "nc-icon nc-vector",
        title: "Helpdesk",
        value: helpdeskHours,
        footerText: "In the last hour",
        footerIcon: "nc-icon nc-bell-55",
      },
      {
        type: "info",
        icon: "nc-icon nc-favourite-28",
        title: "Totals",
        value: totalHours,
        footerText: "Updated now",
        footerIcon: "nc-icon nc-refresh-69",
      },
    ];

    return {
      loadStats,
      statsCards,
      orderName,
      orderFilter: [{ key: "orderId", value: props.id }],
    };
  },
};
</script>
