<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-6">
          <h5>Assigned to</h5>
        </div>
        <div class="col-md-6">
          <div class="float-right"></div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <el-table
        class="table-striped"
        :data="assigned.data"
        border
        style="width: 100%"
        max-height="300"
        :default-sort="{
          prop: assigned.sorting.column,
          order: assigned.sorting.order,
        }"
        :show-summary="true"
        sum-text="Totals"
      >
        <el-table-column
          v-for="column in assigned.tableColumns"
          :key="column.label"
          :min-width="column.minWidth"
          :width="column.width"
          :prop="column.prop"
          :label="column.label"
          :sortable="column.sortable"
        >
          <template v-if="column.customLayout === 'progress'" #default="scope">
            <Progress
              :type="
                (scope.row.personActualTime * 100) /
                  scope.row.personEstimatedTime >
                100
                  ? 'danger'
                  : 'primary'
              "
              :value="
                (scope.row.personActualTime * 100) /
                scope.row.personEstimatedTime
              "
            ></Progress>
          </template>
        </el-table-column>
        <el-table-column
          :min-width="120"
          fixed="right"
          class-name="td-actions"
          label="Actions"
        >
          <template #default="props">
            <p-button
              type="info"
              size="sm"
              icon
              @click="handleShow(props.$index, props.row)"
            >
              <i class="fa fa-user"></i>
            </p-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { onMounted, computed, reactive, ref } from "vue";
import { Progress } from "src/components/UIComponents";
import { useRouter } from "vue-router";

export default {
  components: {
    Progress,
  },
  props: {
    id: {
      type: Number,
      default: null,
      description: "Person id",
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const showModal = ref(false);
    const loading = ref(false);

    onMounted(() => {
      store.dispatch("persons/fetchOrders", {
        personId: props.id,
        page: assigned.pagination.currentPage,
        perPage: assigned.pagination.perPage,
        orderBy: assigned.sorting.column,
        sortOrder: assigned.sorting.order,
        withStats: true,
      });
    });

    /* Orders which this person is assigned to */
    const assigned = reactive({
      data: computed(() => store.state.persons.orders),
      pagination: { perPage: null, currentPage: null },
      sorting: { prop: "id", order: "asc" },
      tableColumns: [
        {
          prop: "name",
          label: "Order",
          minWidth: 80,
          sortable: "custom",
        },
        {
          prop: "customer.companyName",
          label: "Customer",
          minWidth: 80,
          sortable: "custom",
        },
        {
          prop: "personActualTime",
          label: "Committed",
        },
        {
          prop: "personEstimatedTime",
          label: "Estimated",
        },
        {
          prop: "personEstimatedTime",
          label: "Effort",
          customLayout: "progress",
          minWidth: 120,
        },
      ],
    });

    const handleShow = (index, row) => {
      router.push(`/orders/details/${row.id}`);
    };

    return {
      assigned,
      showModal,
      loading,
      handleShow,
    };
  },
};
</script>
<style></style>
