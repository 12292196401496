<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">Create Order</h4>
    </div>
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-md-6">
            <fg-input
              v-model="order.name"
              type="text"
              label="Order name"
              placeholder=""
              name="name"
            >
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input
              v-model="order.description"
              type="text"
              label="Description"
              placeholder="Write a short description"
              name="description"
            >
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <fg-input
              v-model="order.estimatedValue"
              type="number"
              label="Estimated value"
              placeholder="in €"
              name="estimatedValue"
            >
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input
              v-model="order.estimatedTime"
              type="number"
              label="Estimated time"
              placeholder="in hours"
              name="estimatedTime"
            >
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input
              v-model="order.validityStart"
              type="date"
              label="Valid from"
              placeholder="Select date"
              name="validityStart"
            >
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input
              v-model="order.validityEnd"
              type="date"
              label="Valid until"
              placeholder="Select date"
              name="validityEnd"
            >
            </fg-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <fg-input
              :model-value="order.contactId"
              type="number"
              label="Customer"
              placeholder="Search for customer"
              name="contactId"
            >
              <template #default="slotProps">
                <el-select
                  v-model="slotProps.inputValue"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="Search for customer"
                  :remote-method="searchCustomers"
                  :loading="loading"
                  @change="slotProps.updateValue($event)"
                >
                  <el-option
                    v-for="item in customers"
                    :key="item.id"
                    :label="item.companyName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </template>
            </fg-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <fg-input :model-value="order.status" name="status" label="Status">
              <template #default="slotProps">
                <el-select
                  v-model="slotProps.inputValue"
                  single
                  class="select-default"
                  size="large"
                  placeholder="Status"
                  @change="slotProps.updateValue($event)"
                >
                  <el-option
                    v-for="option in selects.status"
                    :key="option.label"
                    :value="option.value"
                    class="select-default"
                    :label="option.label"
                  >
                    <Tag :type="'order-' + option.value">
                      {{ option.label.charAt(0) }}
                    </Tag>
                    {{ option.label }}
                  </el-option>
                </el-select>
              </template>
            </fg-input>
          </div>
        </div>
        <div v-if="mode === 'edit'" class="text-center">
          <button
            type="submit"
            class="btn btn-info btn-fill btn-wd"
            @click.prevent="updateOrder"
          >
            Update Order
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { object, string, date, number } from "yup";
import { useForm } from "vee-validate";
import { ElSelect } from "element-plus";
import { ref, computed } from "vue";
import { Tag } from "src/components/UIComponents";

export default {
  components: {
    ElSelect,
    Tag,
  },
  props: {
    id: {
      type: Number,
      default: -1,
      description: "Id of the order",
    },
    mode: {
      type: String,
      default: "edit",
      description: "form in show|edit|create mode",
    },
  },
  setup(props) {
    const store = useStore();
    //const route = useRoute();
    const order = store.getters["orders/getOrder"](props.id) ?? {};

    const schema = object().shape({
      name: string().required("This field is required"),
      description: string().required("This field is required"),
      validityStart: date()
        .required()
        .typeError("This field must be a valid date dd/mm/yyyy"),
      validityEnd: date()
        .required()
        .typeError("This field must be a valid date dd/mm/yyyy"),
      estimatedTime: number()
        .required()
        .min(1)
        .typeError("This field must be a number"),
      estimatedValue: number()
        .required("This field is required")
        .min(1)
        .typeError("This field must be a number"),
      contactId: number().required().typeError("This field is required"),
      status: string()
        .oneOf(["offer", "completed", "working", "blocked", "lost"])
        .required("This field is required"),
    });

    const { handleSubmit, validate, values } = useForm({
      validationSchema: schema,
    });

    handleSubmit((values, { resetForm }) => {
      store.dispatch("authenticate", values).then(({ result }) => {
        if (result === true) {
          // reset the form and the field values to their initial values
          resetForm();
        }
      });
    });

    const loading = ref(false);
    const customers = computed(() =>
      store.getters["contacts/getContactsByType"]("customer")
    );
    const searchCustomers = (query) => {
      if (query) {
        loading.value = true;
        store.dispatch("contacts/fetchContacts", {
          type: "customer",
          page: 1,
          perPage: 10,
          searchTerm: query,
          searchFields: ["companyName"],
        });
        loading.value = false;
      } else {
        customers.value = [];
      }
    };

    return {
      order,
      searchCustomers,
      customers,
      loading,
      async validate() {
        return { result: await validate(), values: values };
      },
      selects: {
        status: [
          { value: "offer", label: "Offer" },
          { value: "completed", label: "Completed" },
          { value: "working", label: "Working" },
          { value: "blocked", label: "Blocked" },
          { value: "lost", label: "Lost" },
        ],
      },
    };
  },
  methods: {
    updateOrder() {
      alert("Your data: " + JSON.stringify(this.order));
    },
  },
};
</script>
<style></style>
