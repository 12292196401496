<template>
  <div class="card card-user">
    <div class="image">
      <img src="https://picsum.photos/400" alt="..." />
    </div>
    <div class="card-body">
      <div class="author">
        <router-link :to="`/persons/details/${person?.id}`">
          <avatar class="avatar border-gray" :text="fullname" />
          <h5 class="title">{{ person?.firstName }} {{ person?.lastName }}</h5>
        </router-link>
      </div>
    </div>
    <div class="card-footer">
      <hr />
      <div class="button-container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-6 ml-auto">
            <router-link
              v-if="!!person?.userId"
              :to="`/admin/users/details/${person.userId}`"
              ><h5>
                <i class="nc-icon nc-single-02"></i>
                <br />
                <small>Account</small>
              </h5>
            </router-link>
            <p-button
              v-else
              type="neutral"
              size="sm"
              class="mr-2"
              @click="showModal = true"
            >
              Link user
            </p-button>
          </div>
          <div class="col-lg-4 col-md-6 col-6 ml-auto mr-auto">
            <h5>
              {{ thisYearCost }}
              <br />
              <small>Cost</small>
            </h5>
          </div>
          <div class="col-lg-3 mr-auto">
            <h5>
              {{ lastYearCost }}
              <br />
              <small>Last year</small>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <modal class="modal-primary" :show="showModal" @close="showModal = false">
      <template #header>
        <div class="modal-profile ml-auto mr-auto">
          <i class="nc-icon nc-badge"></i>
        </div>
      </template>
      <fg-input v-model="newLink" label="Search a user" required>
        <template #default="slotProps">
          <el-select
            v-model="slotProps.inputValue"
            class="select-default"
            filterable
            remote
            :placeholder="`Search for a user`"
            :remote-method="searchUser"
            :loading="loading"
            @change="slotProps.updateValue($event)"
          >
            <el-option
              v-for="item in candidates"
              :key="item.id"
              :label="item.firstName + ' ' + item.lastName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>
      </fg-input>
      <template #footer>
        <div class="left-side">
          <p-button type="default" link @click="showModal = false"
            >Back</p-button
          >
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button
            type="default"
            :disabled="newLink === null"
            link
            @click="onLink()"
            >Link</p-button
          >
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { Avatar, Modal } from "src/components/UIComponents";
//import { useRoute } from "vue-router";

export default {
  components: {
    Avatar,
    Modal,
  },
  props: {
    id: {
      type: Number,
      default: -1,
      description: "Id of the person",
    },
  },
  setup(props) {
    const store = useStore();
    const person = computed(() => store.state.persons.person);
    const thisYearCost = ref(0);
    const lastYearCost = ref(0);
    const showModal = ref(false);
    const loading = ref(false);
    const candidates = computed(() => store.state.users.users);
    const newLink = ref(null);

    const searchUser = (query) => {
      if (query) {
        loading.value = true;
        store
          .dispatch("users/fetchUsers", {
            perPage: 10,
            searchTerm: query,
            searchFields: ["firstName", "lastName"],
          })
          .then(() => {
            loading.value = false;
          })
          .catch(() => (loading.value = false));
      } else {
        candidates.value = [];
      }
    };

    /* Assign Modal - Assign */
    const onLink = () => {
      store
        .dispatch("persons/updatePerson", {
          personValues: { userId: newLink.value },
          id: props.id,
        })
        .then(({ result }) => {
          if (result === true) {
            console.log("show message");
            showModal.value = false;
          }
        });
    };

    return {
      showModal,
      loading,
      searchUser,
      onLink,
      newLink,
      candidates,
      person,
      thisYearCost,
      lastYearCost,
      fullname: computed(
        () => `${person.value.firstName} ${person.value.lastName}`
      ),
    };
  },
};
</script>
<style></style>
