<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">Edit Person</h4>
    </div>
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-md-5">
            <fg-input
              :model-value="person.companyRole"
              name="companyRole"
              label="Company Role"
            >
              <template #default="slotProps">
                <el-select
                  v-model="slotProps.inputValue"
                  single
                  class="select-default"
                  size="large"
                  placeholder="Role"
                  @change="slotProps.updateValue($event)"
                >
                  <el-option
                    v-for="option in selects.roles"
                    :key="option.label"
                    class="select-default"
                    :value="option.value"
                    :label="option.label"
                  >
                  </el-option>
                </el-select>
              </template>
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <fg-input
              v-model="person.firstName"
              type="text"
              label="First Name"
              placeholder="First Name"
              name="firstName"
            >
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input
              v-model="person.lastName"
              type="text"
              label="Last Name"
              placeholder="Last Name"
              name="lastName"
            >
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <fg-input
              :model-value="person.status"
              name="status"
              label="Resource availability"
            >
              <template #default="slotProps">
                <el-select
                  v-model="slotProps.inputValue"
                  single
                  class="select-default"
                  size="large"
                  placeholder="Enabled/Disabled"
                  @change="slotProps.updateValue($event)"
                >
                  <el-option
                    v-for="option in selects.enable"
                    :key="option.label"
                    class="select-default"
                    :value="option.value"
                    :label="option.label"
                  >
                  </el-option>
                </el-select>
              </template>
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input
              v-model="person.cost"
              type="number"
              label="Cost per hour"
              placeholder="€/h"
              name="cost"
            >
            </fg-input>
          </div>
        </div>
        <div v-if="mode === 'edit'" class="text-center">
          <button
            type="submit"
            class="btn btn-info btn-fill btn-wd"
            @click.prevent="onSubmit"
          >
            Update Person
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { object, string } from "yup";
import { useForm } from "vee-validate";
import { ElSelect } from "element-plus";
import { ref, computed } from "vue";

export default {
  components: {
    ElSelect,
  },
  props: {
    id: {
      type: Number,
      default: -1,
      description: "Id of the person",
    },
    mode: {
      type: String,
      default: "edit",
      description: "form in show|edit|create mode",
    },
  },
  setup(props) {
    const store = useStore();
    //const route = useRoute();
    const person = computed(() => store.state.persons.person);

    const defaults = ref(person);

    const schema = object().shape({
      companyRole: string()
        .oneOf([
          "developer",
          "administrator",
          "employee",
          "salesman",
          "freelancer",
        ])
        .required("This field is required"),
      status: string().oneOf(["enabled", "disabled"]).required(),
      firstName: string().required("This field is required"),
      lastName: string().required("This field is required"),
    });

    const { handleSubmit, validate, values } = useForm({
      validationSchema: schema,
      initialValues: defaults,
    });

    const onSubmit = handleSubmit((values) => {
      store
        .dispatch("persons/updatePerson", {
          personValues: values,
          id: props.id,
        })
        .then(({ result }) => {
          if (result === true) {
            console.log("show message");
          }
        });
    });

    return {
      onSubmit,
      person,
      selects: {
        enable: [
          { value: "enabled", label: "Enabled" },
          { value: "disabled", label: "Disabled" },
        ],
        roles: [
          { value: "developer", label: "Developer" },
          { value: "administrator", label: "Administrator" },
          { value: "employee", label: "Employee" },
          { value: "salesman", label: "Salesman" },
          { value: "freelancer", label: "Freelancer" },
        ],
      },
      async validate() {
        return { result: await validate(), values: values };
      },
    };
  },
};
</script>
<style></style>
