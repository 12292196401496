<template>
  <Form
    v-slot="{ handleSubmit }"
    :validation-schema="schema"
    @submit="handleSubmit($event, submit)"
  >
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Register Form</h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Email address</label>

          <fg-input v-model="email" type="email" name="email"> </fg-input>
        </div>
        <div class="form-group">
          <label>Password</label>

          <fg-input v-model="password" type="password" name="password">
          </fg-input>
        </div>
        <div class="form-group">
          <label>Confirm Password</label>

          <fg-input v-model="confirm" type="password" name="confirm">
          </fg-input>
        </div>
      </div>
      <div class="card-footer text-right">
        <p-checkbox class="pull-left">Subscribe to newsletter</p-checkbox>
        <p-button type="info" native-type="submit">Register</p-button>
      </div>
    </div>
  </Form>
</template>
<script>
import { Form } from "vee-validate";
import { object, string, ref } from "yup";

export default {
  components: {
    Form,
  },
  data() {
    const schema = object().shape({
      email: string().email().required(),
      password: string().min(6).required(),
      confirm: string()
        .required()
        .oneOf([ref("password")], "Passwords do not match"),
    });

    return {
      email: "",
      password: "",
      confirm: "",
      schema: schema,
    };
  },
  methods: {
    submit() {
      alert("Form has been submitted!");
    },
  },
};
</script>
<style></style>
